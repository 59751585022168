.navbar {
  margin: 3px;
  cursor: pointer;
}

.navbar.active {
  text-decoration: underline;
  /* text-decoration-color: #9f7aea;
  color: #9f7aea; */
}
